import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import { set } from "lodash";
import { APISET } from "../actions/TLCAPI";
import get from "central-payment/Main/lib/FieldForm/rc-util/utils/get";
//这三个pagekey的页面不在个人中心
//当跳转到这些pagekey所代表的页面时Key 需变成 userinfo
export const WILLUPDATETODEFAULT_KEYARRAY = ["mypromotion","mybonus","dailybonus"]
export const DEFAULT_TABKEY = "userinfo";
import { checkSelfExlusionFn } from "../actions/helper";

let changeableKey = DEFAULT_TABKEY;
if(typeof Storage !== 'undefined'){
    changeableKey = !!sessionStorage.getItem("userCenterPageKey") ? sessionStorage.getItem("userCenterPageKey") : DEFAULT_TABKEY;
}

export const fetchMemberSelfExlusionStatus = createAsyncThunk(
    'userCenter/fetchMemberSelfExlusionStatus',
    async () => {
        const isDisabled = await checkSelfExlusionFn();
        return isDisabled;
    }
);


const userCenterSlice = createSlice({
    name: "userCenter",
    initialState: {
        memberInfo: {},
        isPersonalDataEditable: false, //帳戶資料-基本訊息是否可編輯
        userCenterPageTabKey: changeableKey,
        refreshCurrentPage: "",   //当前页面是否需要重新请求一次数据 (充值： deposit，转账：transfer，提款：withdraw)
        isDisableFromSelfExclude: null,
    },
    reducers: {
        setMemberInfo(state, action) {
            state.memberInfo = action.payload;
            const { firstName, dob, gender } = action.payload;
            if (firstName.length && dob && gender) {
                state.isPersonalDataEditable = false;
            } else {
                state.isPersonalDataEditable = true;
            }
        },
        changeUserCenterTabKey(state, action) {
            state.userCenterPageTabKey = WILLUPDATETODEFAULT_KEYARRAY.some((item)=> item === action.payload) ? DEFAULT_TABKEY : action.payload;
            sessionStorage.setItem("userCenterPageKey",state.userCenterPageTabKey)
        },
        setRefreshCurrentPage(state,action){
            state.refreshCurrentPage = action.payload;
        },
        resetData(state) {
            state.memberInfo = {};
            state.isPersonalDataEditable = false;
            state.userCenterPageTabKey = DEFAULT_TABKEY;
            state.refreshCurrentPage = "";
        },
        setIsDisableFromSelfExclude(state,action){
            state.isDisableFromSelfExclude = action.payload;
        },
    },
        extraReducers: (builder) => {
            builder
                .addCase(fetchMemberSelfExlusionStatus.pending, (state) => {
                    state.loading = true;
                    state.error = null;
                })
                .addCase(fetchMemberSelfExlusionStatus.fulfilled, (state, action) => {
                    state.loading = false;
                    state.isDisableFromSelfExclude = action.payload;
                })
                .addCase(fetchMemberSelfExlusionStatus.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                });
        }
});

export default userCenterSlice;
export const userCenterActions = userCenterSlice.actions;